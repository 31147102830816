import React, { Component } from 'react';
import { GrossMarginByAccountFilterControl } from '../../elements';
import LinearProgress from '@material-ui/core/LinearProgress';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import GorssMarginByAccountCustomerDetails from './GorssMarginByAccountCustomerDetails'
import GrossMarginISOandItsCompany from './GrossMarginISOandItsCompany'
const useStyles = makeStyles(styles);

class GrossMarginByAccountContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            account: null,
            currentDepth: 1
        };
        this.getSearchByAccount = this.getSearchByAccount.bind(this);
        this.getCurrentDepth = this.getCurrentDepth.bind(this)
    }

    getSearchByAccount(account) {
        this.setState({
            account
        })
    }
    getCurrentDepth(d) {
        this.setState({
            currentDepth: d
        })
    }

    GrossMarginByAccountContainerUI = () => {
        const classes = useStyles();
        // console.log(this.props.grossMarginChartData)
        if (!this.props.grossMarginChartData) {
            return <LinearProgress />
        } else {
            return (
                <>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={3}>
                            <GrossMarginByAccountFilterControl
                                grossMarginChartData={this.props.grossMarginChartData}
                                onChange={this.props.onFilterChange}
                                account={this.state.account}
                                getCurrentDepth={this.getCurrentDepth}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={9}>
                            <Card>
                                <CardHeader>
                                    <h4 className={classes.cardTitle}>
                                        ISO and EDC Level Details</h4>
                                </CardHeader>
                                <CardBody>
                                    <GrossMarginISOandItsCompany
                                        grossMarginChartData={this.props.grossMarginChartData} />
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                    <GorssMarginByAccountCustomerDetails
                        grossMarginChartData={this.props.grossMarginChartData} />

                </>
            )
        }
    }
    render() {
        //console.log(this.props.grossMarginChartData)
        // console.log(this.props.grossMarginByAccount)
        return (<this.GrossMarginByAccountContainerUI />)
    }
}

export default GrossMarginByAccountContainer;