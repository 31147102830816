//dashboard component
import Dashboard from "views/Dashboard/Dashboard.js";
//import AnalysisFiveContract from 'views/Dashboard/AnalysisFiveContract.js';
import AccountLevelDetails from 'views/Operations/AccountLevelDetails';
import ForwardGrossMarginOverTime from 'views/Financials/ForwardGrossMarginOverTime';
import IncomeStatementView from 'views/Financials/IncomeStatementView';
//risk analysis component
/*import RiskAnalysis_Summary from 'views/RiskAnalysis/RiskAnalysis_Summary.js';
import CashMonthNOP from 'views/RiskAnalysis/CashMonthNOP'
import PNLMTM from 'views/RiskAnalysis/PNLMTM'
import PromptMonth_NOP from 'views/RiskAnalysis/PromptMonth_NOP'
import TermNOP from 'views/RiskAnalysis/TermNOP'*/

//import TimelineIcon from '@material-ui/icons/Timeline';
import DashboardIcon from "@material-ui/icons/Dashboard";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
//import WarningIcon from '@material-ui/icons/Warning';
//import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import ReceiptIcon from '@material-ui/icons/Receipt';
import DetailsIcon from '@material-ui/icons/Details';
import ForwardIcon from '@material-ui/icons/Forward';
var dashRoutes = [
  /* {
     collapse: true,
     name: "Dashboard",
     icon: DashboardIcon,
     state: "pageCollapse",
     views: [
       {
         path: "/dashboard-overview",
         name: "Overview",
         icon: RemoveRedEyeIcon,
         //   mini: "IS",
         component: Dashboard,
         layout: "/admin"
       },
       {
         path: "/analysisfivecontract",
         name: "Analysis of 5 Contracts",
         icon: TimelineIcon,
         component: AnalysisFiveContract,
         layout: "/admin"
       },
     ]
   },*/
  {
    name: "Dashboard",
    icon: DashboardIcon,
    path: "/dashboard-overview",
    component: Dashboard,
    layout: "/admin"
  },
  {
    collapse: true,
    name: "Financials",
    icon: AttachMoneyIcon,
    path: "/income-statement",
    component: IncomeStatementView,
    layout: "/admin",
    state: "componentsCollapse",
    views: [
      /*{
        path: "/overview-income",
        name: "Overview",
        //   mini: "IS",
        icon: RemoveRedEyeIcon,
        component: Income,
        layout: "/admin"
      },*/
      {
        path: "/income-statement",
        name: "Income Statement",
        //   mini: "IS",
        icon: ReceiptIcon,
        component: IncomeStatementView,
        layout: "/admin"
      },
     /* {
        path: "/account-level-details",
        name: "Account Level Details",
        //  mini: "ALD",
        icon: DetailsIcon,
        component: AccountLevelDetails,
        layout: "/admin"
      },*/
      {
        path: "/forward-gross-margin-over-time",
        name: "Forward Gross Margin",
        //   mini: "FGMOT",  
        icon: ForwardIcon,
        component: ForwardGrossMarginOverTime,
        layout: "/admin"
      },
    ]
  },
  {
    name: "Operations",
    icon: DetailsIcon,
    path: "/operations-overview",
    component: AccountLevelDetails,
    layout: "/admin"
  },
  //hide the risk analysis UI component
  /*{
    collapse: true,
    name: "Risk Analysis",
    icon: WarningIcon,
    path: "/riskAnalysis-summary",
    component: RiskAnalysis_Summary,
    //   mini: "IS",
    layout: "/admin",
    //state: "componentsCollapse",
    views: [
      {
        path: "/riskAnalysis-summary",
        name: "Summary",
        //   mini: "IS",
        icon: RemoveRedEyeIcon,
        component: RiskAnalysis_Summary,
        layout: "/admin"
      },
      {
        path: "/pnl-and-mtm",
        name: "PNL and MTM",
        //   mini: "IS",
        icon: ReceiptIcon,
        component: PNLMTM,
        layout: "/admin"
      },
      {
        path: "/cash-month-nop",
        name: "Cash Month NOP",
        //  mini: "ALD",
        icon: DetailsIcon,
        component: CashMonthNOP,
        layout: "/admin"
      },
      {
        path: "/prompt-month-nop",
        name: "Prompt Month NOP",
        //   mini: "FGMOT",  
        icon: ForwardIcon,
        component: PromptMonth_NOP,
        layout: "/admin"
      },
      {
        path: "/term-nop",
        name: "Term NOP",
        //   mini: "FGMOT",  
        icon: ForwardIcon,
        component: TermNOP,
        layout: "/admin"
      },
    ]
  },*/
];
export default dashRoutes;
