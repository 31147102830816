import React, { Component } from 'react';
import {
    useTable, useExpanded, useGroupBy, useRowSelect, useFilters, useSortBy, useBlockLayout, useResizeColumns
} from 'react-table'
import { formatter, capitalize, getMonths } from '../../utils/helper';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import styled from 'styled-components';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TimelineIcon from '@material-ui/icons/Timeline';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useSticky } from 'react-table-sticky';
//import { useSticky } from '../../utils/useSticky.tsx';
import {
    Area, XAxis, YAxis, Tooltip, CartesianGrid, Legend,
    ComposedChart, Bar, Line, ResponsiveContainer
} from 'recharts';
import { CustomToolTipMWh, CustomToolTipIncomeStatement, CustomizedAxisTick, CustomToolTipDollar, CustomYAxisMWh, CustomYAxisDollar } from '../../utils/rechartHelper'
import { onClick_DownloadIamge_GroupingTable } from '../../utils/downloadGraphImage'
import { ColorButtonAlignRight } from '../../utils/materialUI_Reuseable_Comp'

// style the each cell
const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: 'rgb(21, 96, 153)',
        color: theme.palette.common.white,
        fontSize: 16,
        textAlign: 'left',
        paddingLeft: '16px'

    },
}))(TableCell);
// style each rpw
const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        "&:hover": {
            backgroundColor: "#B6FD9B !important"
        },
    },
}))(TableRow);

// style for table
const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        overflowX: 'auto',
        boxShadow: '1px 1px 2px 1px #ccc',
        // display: 'absolute',
    },
    table: {
        //  width: "100%",
    },
}));

function useControlledState(state) {
    return React.useMemo(() => {
        if (state.groupBy.length) {
            return {
                ...state,
                hiddenColumns: [...state.hiddenColumns, ...state.groupBy].filter(
                    (d, i, all) => all.indexOf(d) === i
                ),
            }
        }
        return state
    }, [state])
}

const Styles = styled.div`
.table {
    display: inline-block;
    .tr {
        
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th{
       
        padding: 0.5rem;
            border-bottom: 1px solid #ddd;
            border-right: 1px solid #ddd;
    }
    .td {
       
        padding: 0.5rem;
      border-bottom: 1px solid #ddd;
      border-right: 1px solid #ddd;
      border-left: 1px solid #ddd;
      font-size: 15px;
    }
    ${'' /* In this example we use an absolutely position resizer,
so this is required. */}
position: relative;

:last-child {
 border-right: 0;
}

.resizer {
 display: inline-block;
 background: white;
 width: 5px;
 height: 100%;
 position: absolute;
 right: 0;
 top: 0;
 transform: translateX(50%);
 z-index: 1;
 ${'' /* prevents from scrolling while dragging on touch devices */}
 touch-action:none;

 &.isResizing {
   background: green;
 }
    &.sticky {
        .header,
        .footer {
          position: sticky;
          z-index: 1;
        }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        box-shadow: 2px 0px 3px #ccc;
      }

      [data-sticky-first-right-td] {
        box-shadow: -2px 0px 3px #ccc;
      }
    }
  }
`;
//a table using material UI
//const { yearList, monthList, view, newData } = this.props; 
export class MaterialUITableGraph extends Component {

    constructor() {
        super();
        this.state = {
            activeIndex: 5,
            selectedRowData: null,
            initial: 'state'
        }
        this.MaterialUITable_With_Graph = this.MaterialUITable_With_Graph.bind(this)
        this.OnClickDrawGraphYearly = this.OnClickDrawGraphYearly.bind(this)
    }
    onPieEnter = (data, index) => {
        this.setState({
            activeIndex: index,
        });
    }

    MaterialUITable_With_Graph = ({ columns, data, columGrouping = [], rowSorting = [], listYear, listMonth, yearMonthView, hideExpandColumn }) => {

        // Use the state and functions returned from useTable to build your UI
        const defaultColumn = React.useMemo(
            () => ({
                // When using the useFlexLayout:
                minWidth: 150, // minWidth is only used as a limit for resizing
                width: 500, // width is used for both the flex-basis and flex-grow
                //maxWidth: 300, // maxWidth is only used as a limit for resizing
            }),
            []
        )
        const {
            getTableProps,
            headerGroups,
            toggleAllRowsSelected,//get data for row selected 
            rows, // get the data for row
            prepareRow,
            selectedFlatRows,
        } = useTable(
            {
                initialState: {
                    groupBy: columGrouping,
                    preSortedRows: rowSorting
                },
                columns,
                data,
                listMonth,
                listYear,
                yearMonthView,
                defaultColumn,
                autoResetSelectedRows: false,
            },
            useFilters,
            useGroupBy,
            useSortBy,
            useExpanded,
            useRowSelect,
            useBlockLayout,
            useResizeColumns,
            useSticky,
            hooks => {
                hooks.useControlledState.push(useControlledState)
                hooks.visibleColumns.push((columns) => {
                    return [
                        {
                            id: 'expander', // Make sure it has an ID
                            // Build our expander column
                            Header: ({ allColumns, state: { groupBy } }) => {
                                return groupBy.map(columnId => {
                                    const column = allColumns.find(d => d.id === columnId)
                                    return (
                                        <span  {...column.getHeaderProps()}>
                                            {column.canGroupBy ? (
                                                // If the column can be grouped, let's add a toggle
                                                <span {...column.getGroupByToggleProps()}>
                                                    {column.isGrouped}
                                                </span>
                                            ) : null}
                                            {column.render('Header')}{' '}
                                        </span>
                                    )
                                })
                            },
                            Cell: ({ row }) => {
                                //console.log(row)
                                //console.log(row.subRows.length)
                                const groupedCell = row.allCells.find(d => d.isGrouped)
                                // below if condition handle if the length of subrow is 1 do not expand it else expand it 
                                if (hideExpandColumn !== true) {
                                    if (row.canExpand && row.subRows.length > 1) {
                                        return (
                                            <span
                                                {...row.getToggleRowExpandedProps({
                                                    style: {
                                                        // We can even use the row.depth property
                                                        // and paddingLeft to indicate the depth
                                                        // of the row
                                                        paddingLeft: `${row.depth * 1.2}rem`,
                                                    },
                                                })}
                                            >
                                                {(row.isExpanded && row.subRows.length > 1) ? "⮟ " : row.subRows.length > 1 ? ("⮞ ") : null}
                                                {groupedCell.render('Cell')}{' '}
                                                {/*display length of row*//*row.subRows.length*/}
                                            </span>
                                        )
                                    }
                                }
                                var NonExpandRowPadding = {
                                    paddingLeft: `${row.depth * 1.2}rem`,
                                };
                                // this return if the length of row is 1 then return only the name of row for column 1 and do not expand it further
                                return (
                                    <span style={NonExpandRowPadding}>
                                        {groupedCell.render('Cell')}{' '}
                                        {/*display length of row*//*row.subRows.length*/}
                                    </span>
                                )
                            },
                        },
                        ...columns.map(d => ({ ...d, isVisible: !d.isGrouped })),
                    ]
                })
            }
        )
        const classes = useStyles(); // import table style 
        const Graph_Year_Data = [], Graph_Month_Data = []

        // get the data of each row and create a list of yearly/monthly 
        //console.log(selectedFlatRows)
        //console.log(onHoverGraphstate)
        // format the data to draw graph on hover
        selectedFlatRows.map(d => {
            return yearMonthView === "0" ?
                /*if listYear is null return null */
                listYear != null ? listYear.map(a => {
                    Graph_Year_Data.push({
                        year: a.year,
                        groupByVal: d.groupByVal,
                        Amount: d.values[a.year]
                    })
                    return null
                }) : null
                /*if listMonth is null return null */
                : listMonth != null ? listMonth.map(a => {
                    Graph_Month_Data.push({
                        year: capitalize(a.name),
                        groupByVal: d.groupByVal,
                        Amount: d.values[capitalize(a.name)]
                    })
                    return null
                }) : null
        })
        // below code is use to set the data for graph as user can click the button to veiw the graph of row containing data
        // this function is used to draw graph OnClickDrawGraphYearly in incomeByPorfolio and incomeByIso file
        /*  if (selectedFlatRows[0] !== undefined) {
              console.log('pass')
              console.log(selectedFlatRows[0])
              this.state.selectedRowData = selectedFlatRows[0]
          }*/
        // this return statment will desing table on the basis of hook configure above
        return (
            <>
                <Styles>
                    <div {...getTableProps()} className="table sticky">
                        <Paper className={classes.root}>
                            <Table className={classes.table}>
                                <TableHead >
                                    {headerGroups.map(headerGroup => (
                                        <StyledTableRow {...headerGroup.getHeaderGroupProps()} className="tr" >
                                            {headerGroup.headers.map(column => (
                                                <StyledTableCell {
                                                    ...column.getHeaderProps()
                                                } className="th" >
                                                    <>
                                                        {column.canGroupBy ? (
                                                            // If the column can be grouped, let's add a toggle
                                                            <span {...column.getGroupByToggleProps()}>
                                                                {column.isGrouped}
                                                            </span>
                                                        ) : null}
                                                        {column.render("Header")}
                                                        {/* Use column.getResizerProps to hook up the events correctly */}
                                                        <div
                                                            {...column.getResizerProps()}
                                                            className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                                                        />
                                                    </>
                                                </StyledTableCell>
                                            ))}
                                        </StyledTableRow>
                                    ))}
                                </TableHead>
                                <TableBody >
                                    {rows.map((row) => {
                                        prepareRow(row)
                                        return (
                                            <StyledTableRow  {...row.getRowProps({
                                                style: {
                                                    backgroundColor: row.isSelected ? '#B6FD9B' : '#FFFFFF',
                                                    //  marginBottom: removeExtraMarginBottom === true ? -60 : 0
                                                },
                                                onMouseEnter: () => {
                                                    toggleAllRowsSelected(false)
                                                    row.toggleRowSelected()
                                                },
                                            })}
                                                className="tr"
                                            >
                                                {row.cells.map(cell => {
                                                    return (
                                                        <StyledTableCell {...cell.getCellProps({
                                                            style: {
                                                                backgroundColor: row.isSelected ? '#B6FD7B' : '#FFFFFF',
                                                            },
                                                        })}
                                                            className="td"
                                                        >
                                                            {cell.isGrouped ? (
                                                                // If it's a grouped cell, add an expander and row count
                                                                <>
                                                                    <span {...row.getExpandedToggleProps()}>
                                                                        {row.isExpanded && row.subRows.length > 1 ? "⮟ " : row.subRows.length > 1 ? ("⮞ ") : null}
                                                                    </span>{" "}
                                                                    {cell.render('Cell', { editable: false })}
                                                                    {/*display length of row*//*row.subRows.length*/}
                                                                </>
                                                            ) : cell.isAggregated ? (
                                                                // If the cell is aggregated, use the Aggregated
                                                                // renderer for cell
                                                                cell.render("Aggregated")
                                                            ) : cell.isRepeatedValue ? ( // For cells with repeated values, render null
                                                                // Otherwise, just render the regular cell
                                                                cell.render("Cell")
                                                            ) : null}
                                                        </StyledTableCell>
                                                    )
                                                })}
                                            </StyledTableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </Paper>
                    </div>
                </Styles>
            </>
        )
    }
    // arrow function to draw graph when user click on button on table
    OnClickDrawGraphYearly = ({ yearView }) => {
        const [open, setOpen] = React.useState(false); // handle the state of open/close
        const [GraphSelection, setGraphSelection] = React.useState('0'); //view different type of graph
        const [GraphUnit_YAxis, setGraphUnit_YAxis] = React.useState(0); //view different type of graph

        var selectedRowData_KeyPairValue = []; // reformat data where rechart understand 
        const classes = useStyles();
        // style of popper
        const styles = theme => ({
            root: {
                margin: 0,
                padding: theme.spacing(2),
            },
            closeButton: {
                position: 'absolute',
                right: theme.spacing(1),
                top: theme.spacing(1),
                color: theme.palette.grey[500],
            },
        });
        // style of popper
        const DialogTitle = withStyles(styles)(props => {
            const { children, classes, onClose, ...other } = props;
            return (
                <MuiDialogTitle disableTypography className={classes.root} {...other}>
                    <Typography variant="h6">{children}</Typography>
                    {onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                </MuiDialogTitle>
            );
        });
        // style of popper
        const DialogContent = withStyles(theme => ({
            root: {
                padding: theme.spacing(2),
            },
        }))(MuiDialogContent);
        // style of popper
        const DialogActions = withStyles(theme => ({
            root: {
                margin: 0,
                padding: theme.spacing(1),
            },
        }))(MuiDialogActions);
        const handleClickOpen = () => {
            if (this.state.selectedRowData !== null) {
                if (this.state.selectedRowData.values.type === 'Volume') {
                    setGraphUnit_YAxis(1) // change the unit sign for y-axis for graph
                }
            }
            setOpen(true);
        };
        const handleClose = () => {
            this.setState({ selectedRowData: null });
            setOpen(false);
        };
        const handleGraphSelection = event => {
            this.setState({ view: event.target.value })
            //console.log(event.target.value)
            setGraphSelection(event.target.value);
        };
        //year list to display on x-axis
        const years = [{ year: '2020', id: 0 }, { year: '2021', id: 1 }, { year: '2022', id: 2 }, { year: '2023', id: 3 }, { year: '2024', id: 4 }]
        // get list of month
        const setMonths = getMonths();
        // check it the selected row data is not null
        //console.log(this.state.selectedRowData)
        if (this.state.selectedRowData !== null) {
            const rowData = this.state.selectedRowData.values; // extract original data
            // console.log(rowData);
            // console.log(yearView)
            // handle the view option by yearly/monthly
            if (yearView === true) {
                years.map(a => {
                    if (rowData[2020 + a.id] !== undefined) {
                        //console.log(rowData[2020 + a.id])
                        // create data for year view 
                        selectedRowData_KeyPairValue.push({
                            year: a.year,
                            name: rowData.name,
                            type: rowData.type,
                            value: rowData[2020 + a.id] !== undefined ? rowData[2020 + a.id] : 0,
                        })
                    }
                    return null
                })
            } else if (this.state.selectedRowData !== null) {
                //console.log(setMonths)
                //console.log(rowData)
                setMonths.map(a => {
                    //console.log(a.name)
                    //console.log(rowData[capitalize(a.name)])
                    if (rowData[capitalize(a.name)] !== undefined) {
                        //console.log(rowData.values[selectedYear].monthly[a.name])
                        //create data for month view
                        selectedRowData_KeyPairValue.push({
                            year: capitalize(a.name),
                            name: rowData.name,
                            type: rowData.type,
                            value: rowData[capitalize(a.name)],
                        })
                    }
                    return null
                })
            } else {
                selectedRowData_KeyPairValue.push({
                    year: null,
                    name: null,
                    type: null,
                    value: null,
                })
            }
        }
        //console.log(selectedRowData_KeyPairValue)
        //console.log(this.state.selectedRowData)
        return (
            <>
                <Button variant="outlined" color="primary" onClick={handleClickOpen}
                    size="small" startIcon={<TimelineIcon />} fullWidth={true}>
                </Button>
                <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                        Graph of <strong>{this.state.selectedRowData !== null ?
                            this.state.selectedRowData.values.type : null}</strong></DialogTitle>
                    <DialogContent dividers>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">Please Select the Graph/Chart View</FormLabel>
                            <RadioGroup aria-label="gender" name="gender1" value={GraphSelection} onChange={handleGraphSelection} style={{ display: 'inline', flexDirection: 'row' }}>
                                <FormControlLabel value='0' control={<Radio color="primary" />} label="Bar" />
                                <FormControlLabel value='1' control={<Radio color="primary" />} label="Area" />
                                <FormControlLabel value='2' control={<Radio color="primary" />} label="Line" />
                                <ColorButtonAlignRight variant="contained" color="primary" className={classes.margin}
                                    onClick={onClick_DownloadIamge_GroupingTable}>
                                    Download Graph</ColorButtonAlignRight>
                            </RadioGroup>
                        </FormControl>

                        {GraphSelection !== '0' ? null :
                            <>
                                <div id="my-node-group">
                                    <ResponsiveContainer height={300} width={600}>
                                        <ComposedChart data={selectedRowData_KeyPairValue}
                                            margin={{ top: 10, right: 40, bottom: 10, left: 50 }}
                                        >
                                            <XAxis dataKey="year" tick={<CustomizedAxisTick />} />
                                            <YAxis tick={GraphUnit_YAxis === 0 ? <CustomYAxisDollar /> : <CustomYAxisMWh />} />
                                            <Legend />
                                            <CartesianGrid stroke="#f5f5f5" />
                                            <Tooltip content={GraphUnit_YAxis === 0 ? <CustomToolTipDollar /> : <CustomToolTipMWh />} />
                                            <Bar dataKey="value" barSize={20} fill="#156099" />
                                        </ComposedChart>
                                    </ResponsiveContainer>
                                </div>
                            </>
                        }
                        {GraphSelection !== '1' ? null :
                            <>
                                <div id="my-node-group">
                                    <ResponsiveContainer height={300} width={600}>
                                        <ComposedChart data={selectedRowData_KeyPairValue}
                                            margin={{ top: 10, right: 40, bottom: 10, left: 50 }}>
                                            <XAxis dataKey="year" tick={<CustomizedAxisTick />} />
                                            <YAxis tick={GraphUnit_YAxis === 0 ? <CustomYAxisDollar /> : <CustomYAxisMWh />} />
                                            <Legend />
                                            <CartesianGrid stroke="#f5f5f5" />
                                            <Tooltip content={<CustomToolTipIncomeStatement />} />
                                            <Area type='monotone' dataKey='value' fill='#156099' stroke='#8884d8' />
                                        </ComposedChart></ResponsiveContainer>
                                </div>
                            </>
                        }
                        {GraphSelection !== '2' ? null :
                            <>
                                <div id="my-node-group">
                                    <ResponsiveContainer height={300} width={600}>
                                        <ComposedChart data={selectedRowData_KeyPairValue}
                                            margin={{ top: 10, right: 40, bottom: 10, left: 50 }}>
                                            <XAxis dataKey="year" tick={<CustomizedAxisTick />} />
                                            <YAxis tick={GraphUnit_YAxis === 0 ? <CustomYAxisDollar /> : <CustomYAxisMWh />} />
                                            <Legend />
                                            <CartesianGrid stroke="#f5f5f5" />
                                            <Tooltip content={<CustomToolTipIncomeStatement />} />
                                            <Line type="monotone" dataKey="value" stroke="#156099" />
                                        </ComposedChart></ResponsiveContainer>
                                </div>
                            </>}

                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleClose} color="primary">
                            Close
                                    </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }

}

export const subStyle = {
    color: 'red'
};
//if the value is negative display in red color else in black color
export const aggregateValue = (value, currency) => {
    const valueToDisplay = currency ? formatter(value, 'currency') : formatter(value, 'volume');
    //console.log(valueToDisplay)
    return parseFloat(value) < 0 ? <span style={subStyle}>({valueToDisplay.replace("-", "")})</span> : <span>{valueToDisplay}</span>
};

//if the value is negative display in red color else in black color
export const aggregateValue_forGraph = (value, currency) => {
    var valueToDisplay = currency ? formatter(value, 'currency') : formatter(value, 'volume');
    var returnData = parseFloat(value) < 0 ? '-' + (valueToDisplay.replace("-", "")) : valueToDisplay
    return returnData
};

export function returnDollarMWhUnit(number) {
    var value = formatter(number * 1000, 'unitMargin')
    return parseFloat(number) < 0 ? <span style={subStyle}>({value.replace("-", "")})</span> : <span>{value}</span>
}
// below function code was repeacted in IncomeByISo and IncomeByPortfolio so it's created seperatly
// below code is use in this variable isoColumns, isoByMonth, portfolioColumns, portfolioByMonth
// as Aggregated: row => AggregatedTable_With_Value(row),
export function AggregatedTable_With_Value(row, year_contractMargin, GrossUnitView) {
    //console.log(row); // display the enitre data for each row
    if (row.row.depth === 0) {
        // display data before expanding the row
        //console.log(row.cell.row.subRows)
        //ContractMargin = Total_Revenue - Total_Cost
        //GrossMargin = Contract Margin + HedgeMTM
        var Total_Revenue = 0, Total_Cost = 0, Hedge_MTM = 0, Fees = 0, Commission = 0, Contract_Margin = 0, Gross_Margin = 0, Unit_Margin = 0
        row.cell.row.subRows.map(a => {
            if (a.groupByVal === 'Total Revenue' || a.groupByVal === 'Contract Revenue') {
                //console.log(a)
                Total_Revenue = a.values[year_contractMargin]
                // console.log(Total_Revenue)
            }
            if (a.groupByVal === 'Total Cost' || a.groupByVal === 'Commodity Costs') {
                // console.log(a)
                Total_Cost = a.values[year_contractMargin]
                //console.log(Total_Cost)
            }
            if (a.groupByVal === 'Hedge MTM') {
                // console.log(a)
                Hedge_MTM = a.values[year_contractMargin]
                // console.log(Hedge_MTM)
            }
            if (a.groupByVal === 'Fees' || a.groupByVal === 'Fee Revenue') {
                // console.log(a)
                Fees = a.values[year_contractMargin]
                // console.log(Hedge_MTM)
            }
            if (a.groupByVal === 'Commission' || a.groupByVal === 'Commission Obligation') {
                // console.log(a)
                Commission = a.values[year_contractMargin]
                // console.log(Hedge_MTM)
            }
            if (a.groupByVal === 'Unit Margin') {
                // console.log(a)
                Unit_Margin = a.values[year_contractMargin]
                //console.log(Volume)
            }
            Contract_Margin = Math.abs(Total_Revenue) - Math.abs(Total_Cost)
            Gross_Margin = Contract_Margin + Hedge_MTM + Fees - Math.abs(Commission)
            return null
        })
        //console.log(row.cell.row.subRows[2].values[year_contractMargin]); 
        if (GrossUnitView === 'unit') {
            return returnDollarMWhUnit(Unit_Margin)
        } else {
            return aggregateValue(/*row.cell.row.subRows[0].values[year_contractMargin]*/Gross_Margin, true)
        }
    } else if (row.row.depth > 0) {
        // display data after expanding the row
        // console.log(row.cell.row.subRows)
        if (GrossUnitView !== "unit") {
            if (row.row.groupByVal === "Volume") { // display data in Mwh if its type is Volumn
                return aggregateValue(row.cell.value, false)
            } else { // display data in $ if its type is not Volumn
                return aggregateValue(row.cell.value, true)
            }
        }
        else if (GrossUnitView === 'unit') {
            if (row.row.groupByVal === "Volume") { // display data in Mwh if its type is Volumn
                return aggregateValue(row.cell.value, false)
            } else {
                return returnDollarMWhUnit(row.cell.value, true)
            }
        }
    }
}
export function AggregatedTable_With_Value_IncomeByPorfolio(row, year_contractMargin, GrossUnitView) {
    if (GrossUnitView !== "unit") {
        if (row.row.groupByVal === "Volume") { // display data in Mwh if its type is Volumn
            return aggregateValue(row.cell.value, false)
        } else { // display data in $ if its type is not Volumn
            return aggregateValue(row.cell.value, true)
        }
    }
    else if (GrossUnitView === 'unit') {
        if (row.row.groupByVal === "Volume") { // display data in Mwh if its type is Volumn
            return aggregateValue(row.cell.value, false)
        } else {
            return returnDollarMWhUnit(row.cell.value, true)
        }
    }
}
export const aggregateValue_NoSign = (value) => {
    return parseFloat(value) < 0 ? <span style={subStyle}>({value.toFixed(2)})</span> : <span>{value.toFixed(2)}</span>
};
export const aggregateValue_NoSign_thousands = (value) => {
    return parseFloat(value) < 0 ? <span style={subStyle}>({formatter(value, 'thousands')})</span> : <span>{formatter(value, 'thousands')}</span>
};
