import teleiosLogoColor from './PPM_white.png';
import teleiosLogoBw from './teleiosLogo.png';
import pulsePowerLogo from './pulse-power-logo.svg';
import shellLogo from './shell-logo.png'
import atlanticLogo from './atlantic_energy.png';
import southbayLogo from './southbay.png';

export const getLogo = (authData) => {
    const groups = authData ? authData.signInUserSession.idToken.payload['cognito:groups'] : ['demo'];
    let logo;
    if (groups.length === 1) {
        const formatGroup = groups[0].toLowerCase();
        switch (formatGroup) {
            case 'demo':
                logo = teleiosLogoColor;
                break;
            case 'pulse':
                logo = pulsePowerLogo;
                break;
            case 'shell':
                logo = shellLogo;
                break;
            case 'atlantic':
                logo = atlanticLogo;
                break;
            case 'southbay':
                logo = southbayLogo;
                break;
            default:
                logo = teleiosLogoColor;
                break;
        }
    } else {
        if (groups.includes('Shell')) {
            logo = shellLogo;
        } else {
            logo = teleiosLogoColor
        }
    }
    return logo;
}

export {
    teleiosLogoColor,
    teleiosLogoBw
}

