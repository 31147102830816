import * as ac from 'accounting';
import * as d3 from 'd3';

export const formatter = (value, formatType) => {
    let valToReturn = null;

    const isZero = checkForZero(value);

    if (value !== null && isZero !== '--') {
        switch (formatType) {
            case 'thousands':
                valToReturn = ac.formatMoney(parseFloat(value), {
                    symbol: "",
                    precision: 0,
                    thousand: ","
                });
                break;
            case 'currency':
                //TODO: format millions (ex. 1.23M)   
                valToReturn = ac.formatMoney(parseFloat(value), {
                    symbol: "$",
                    precision: 0,
                    thousand: ","
                });
                break;
            case 'unitMargin':
                //TODO: format millions (ex. 1.23M)   
                valToReturn = ac.formatMoney(parseFloat(value), {
                    symbol: " $/MWh",
                    format: "%v %s",
                    precision: 0,
                    thousand: ","
                });
                break;
            case 'volume':
                valToReturn = `${parseFloat(value * 0.001).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} MWh`;
                break;
            case 'volume-kwh':
                valToReturn = `${parseFloat(value).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} KWh`;
                break;
            default:
                return value;
        }
    } else {
        valToReturn = isZero;
    }
    return valToReturn;
}

export const clientColors = {
    mainBlue: '#156099',
    mainRed: '#ed1b24',
    mainGrey: '#6d6e72'
}

//parseString should be the date pattern of the input value as described in the K2 docs
//Takes in a date and its pattern and returns a raw date object
export const dateParser = (value, parseString) => {
    const dateParser = d3.timeParse(parseString);
    return dateParser(value);
}

//parseString should be the date pattern of the input value as described in the K2 docs
//Takes in a date value and its format and returns the unified display pattern for our visualizations
export const dateFormatterMonthYear = (value, parseString) => {
    return dateFormatter(value, parseString, "%b %Y")
}

const dateFormatter = (value, parseString, destinationFormat) => {
    const dateParser = d3.timeParse(parseString);
    const dateFormatter = d3.timeFormat(destinationFormat);
    return dateFormatter(dateParser(value));
}

export const getIsoNames = (data) => {
    return [...new Set(data.map(x => x.iso))]
};

export const getEdcNames = (data) => {
    //return excludeDuplicates(data.map(r => r.edcname));
    return [...new Set(data.map(x => x.edcname))]
};

export const getYears = (data) => {
    // return excludeDuplicates(
    //     data.map(r => {
    //         const date = new Date(r.date);
    //         return date.getFullYear().toString();
    //     })
    // )
    return [...new Set(data.map(r => {
        const date = new Date(r.date);
        return date.getFullYear().toString();
    }))];
};

export const getMonthsWithValues = () => {
    let months = {};
    const names = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
    for (var m = 0; m < 12; m++) {
        months[names[m]] = {
            index: m,
            value: 0
        }
    };

    return months;
};

export const getMonths = () => {
    const names = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
    const months = names.map((m, i) => {
        return ({
            name: m,
            index: i
        })
    });
    return months;
};
export const ListMonth = [
    { month: 'jan', id: 0 }, { month: 'feb', id: 1 },
    { month: 'mar', id: 2 }, { month: 'apr', id: 3 },
    { month: 'may', id: 4 }, { month: 'jun', id: 5 },
    { month: 'jul', id: 6 }, { month: 'aug', id: 7 },
    { month: 'sep', id: 8 }, { month: 'oct', id: 9 },
    { month: 'nov', id: 10 }, { month: 'dec', id: 11 },
]

export const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const excludeDuplicates = (arr) => {
    return arr.filter((v, i, a) => a.indexOf(v) === i).sort();
};

export const checkForZero = (value) => {
    return parseFloat(value) === 0 ? '--' : value
};

export const removeSpecialChars = (str) => {
    // console.log(str)
    return null
}
export const monthNumber_to_Name = (number) => {
    if (number === 0) { return "Jan" }
    else if (number === 1) { return "Feb" }
    else if (number === 2) { return "March" }
    else if (number === 3) { return "Apr" }
    else if (number === 4) { return "May" }
    else if (number === 5) { return "June" }
    else if (number === 6) { return "July" }
    else if (number === 7) { return "Aug" }
    else if (number === 8) { return "Sept" }
    else if (number === 9) { return "Oct" }
    else if (number === 10) { return "Nov" }
    else if (number === 11) { return "Dec" }
    else { return null }
}
export const monthNumber_to_OneIncrement = (number) => {
    if (number === 0) { return 1 }
    else if (number === 1) { return 2 }
    else if (number === 2) { return 3 }
    else if (number === 3) { return 4 }
    else if (number === 4) { return 5 }
    else if (number === 5) { return 6 }
    else if (number === 6) { return 7 }
    else if (number === 7) { return 8 }
    else if (number === 8) { return 9 }
    else if (number === 9) { return 10 }
    else if (number === 10) { return 11 }
    else if (number === 11) { return 12 }
    else { return null }
}
export const incrementYearByOne = (number) => {
    return number + 1
}
export const isoColor = (iso) => {
    if (iso === "ERCOT") { return "#D95F02" }
    else if (iso === "ISONE") { return "#E7298A" }
    else if (iso === "MISO") { return "#666666" }
    else if (iso === "NYISO") { return "#1B9E77" }
    else if (iso === "PJM") { return "#389FD3" }
}

export const calculatePercentage = (compare_to, totalValue) => {
    if (compare_to < 0 && totalValue < 0) {
        return -(compare_to * 100) / totalValue;
    } else {
        return (compare_to * 100) / totalValue;
    }
}