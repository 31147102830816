import React from 'react';
import SearchDataInTable, {
    NumberRangeColumnFilter, SelectColumnFilter, DefaultColumnFilter
} from '../../elements/ReactTableReUseAbleComponent/SearchDataInTable'
import { monthNumber_to_Name } from '../../utils/helper'
import { makeStyles } from "@material-ui/core/styles";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { ColorRadioButton, ColorFormlabel } from '../../utils/materialUI_Reuseable_Comp'

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 180,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));
export default function TopBottomAllAccountsDetails({ top20Accounts, bottom20Accounts, allAccountsDetails, isLoadingGraph }) {
    const [viewAccountDetailState, setViewAccountDetailState] = React.useState('2');
    const classes = useStyles();
    var SearchDataTable = new SearchDataInTable();
    //console.log(top20Accounts)
    const handleViewAccountDetailState = event => {
        setViewAccountDetailState(event.target.value);
    };
    const columns = [
        {
            Header: ' ',
            columns: [
                { // get the account number of customers
                    Header: 'Account No',
                    accessor: 'name',
                    Filter: DefaultColumnFilter
                },
                {
                    Header: 'Value',
                    accessor: row => {
                        /*var valToReturn = ac.formatMoney(parseFloat(row.value), {
                            symbol: "$",
                            precision: 0,
                            thousand: ","
                        });
                        //console.log(valToReturn)
                        const a = valToReturn < 0 ? <span style={subStyle}>({valToReturn.replace("-", "")})</span> : <span>{valToReturn}</span>
                        console.log(row.value)
                        //return Object.values(a)[4].children*/
                        return row.value.toFixed(2)
                    },
                    
                    Aggregated: row => {
                        // console.log(row)
                        return row
                    },
                    Filter: NumberRangeColumnFilter,
                    filter: 'between',

                },
                { // display all contract type and filter the data on the basis of selection
                    Header: 'Contract Type',
                    accessor: 'type',
                    Filter: SelectColumnFilter,
                    filter: 'includes'
                },
                {// display all sign date and filter the data on the basis of selection
                    Header: 'Sign Date',
                    accessor: row => {
                        var formatedContractDate = row.contractSignedDate.getDate() + " " + monthNumber_to_Name(row.contractSignedDate.getMonth()) + ", " + row.contractSignedDate.getFullYear()
                        return formatedContractDate
                    },
                    Filter: SelectColumnFilter,
                    filter: 'includes'
                },
                {// display all start date and filter the data on the basis of selection
                    Header: 'Start Date',
                    accessor: row => {
                        var formatedStartDate = row.startDate.getDate() + " " + monthNumber_to_Name(row.startDate.getMonth()) + ", " + row.startDate.getFullYear()
                        return formatedStartDate
                    },
                    Filter: SelectColumnFilter,
                    filter: 'includes',
                },
                {// display all end date and filter the data on the basis of selection
                    Header: 'End Date',
                    accessor: row => {
                        var formatedEndDate = row.endDate.getDate() + " " + monthNumber_to_Name(row.endDate.getMonth()) + ", " + row.endDate.getFullYear()
                        return formatedEndDate
                    },
                    Filter: SelectColumnFilter,
                    filter: 'includes'
                },
            ],
        },
    ]

    const [data, setData] = React.useState()

    // We need to keep the table from resetting the pageIndex when we
    // Update data. So we can keep track of that flag with a ref.
    const skipResetRef = React.useRef(false)

    // When our cell renderer calls updateMyData, we'll use
    // the rowIndex, columnId and new value to update the
    // original data
    const updateMyData = (rowIndex, columnId, value) => {
        // We also turn on the flag to not reset the page
        skipResetRef.current = true
        setData(old =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...row,
                        [columnId]: value,
                    }
                }
                return row
            })
        )
    }

    // After data changes, we turn the flag back off
    // so that if data actually changes when we're not
    // editing it, the page is reset
    React.useEffect(() => {
        skipResetRef.current = false
    }, [data])

    // console.log(columns)
    return (
        <>   {
            isLoadingGraph === 1 ?
                <>
                    <FormControl component="fieldset" className={classes.formControl}>
                        <ColorFormlabel component="legend">Please Select the Account View
                        </ColorFormlabel>
                        <RadioGroup aria-label="gender"
                            name="gender1"
                            value={viewAccountDetailState}
                            onChange={handleViewAccountDetailState}
                            style={{ display: 'inline', flexDirection: 'row' }}>
                            <FormControlLabel value="0" control={<ColorRadioButton />} label="Top 20 Accounts" />
                            <FormControlLabel value="1" control={<ColorRadioButton />} label="Bottom 20 Accounts" />
                            <FormControlLabel value="2" control={<ColorRadioButton />} label="All Accounts" />
                        </RadioGroup>
                    </FormControl>
                    {viewAccountDetailState === '0' ?
                        <SearchDataTable.SearchDataInTableUI
                            columns={columns}
                            data={top20Accounts}
                            updateMyData={updateMyData}
                            skipReset={skipResetRef.current}
                        />
                        : viewAccountDetailState === '1' ?
                            <SearchDataTable.SearchDataInTableUI
                                columns={columns}
                                data={bottom20Accounts}
                                updateMyData={updateMyData}
                                skipReset={skipResetRef.current}
                            />
                            : viewAccountDetailState === '2' ?

                                <SearchDataTable.SearchDataInTableUI
                                    columns={columns}
                                    data={allAccountsDetails}
                                    updateMyData={updateMyData}
                                    skipReset={skipResetRef.current}
                                />
                                : null
                    }
                </>
                : null}
        </>
    )
}