import React, { Component } from 'react';
import {
    XAxis, YAxis, Tooltip, CartesianGrid, Brush, ResponsiveContainer, Bar, BarChart, Cell
} from 'recharts';
import { isoColor } from '../../utils/helper'
// @material-ui/core components
import { aggregateValue } from 'elements/ReactTableReUseAbleComponent/MaterialUITable';
import { CustomizedAxisTickVertical, CustomXAxisDollarVertical, CustomXAxisDollarMWhVertical } from '../../utils/rechartHelper'
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import API, { graphqlOperation } from "@aws-amplify/api";
import { getISOEDCSelectedAccountLevel } from "../../graphQL_Query/AccountDataQuery"
import { LinearProgress } from '@material-ui/core';
class CustomerAccountLevelDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // handle the view change and save the selected ISO and EDC Name
            accountLevelFilters: [],
            // filter the data on the basis of selected ISO and EDC and add filters
            promise_graphlqlAccountFilterData: null,
        };
    }
    static getDerivedStateFromProps(props) {
        var response_AccountFilterData
        var accountLevelFilters = []
        // console.log(props.filtersGraphQLAccountData)
        //  console.log(props.selectedISOFilter)
        //  console.log(props.selectedEDCFilter)
        //create filter list on the basis of props received
        accountLevelFilters.push({
            filters: [
                {
                    name: `${'iso'}`,
                    value: props.selectedISOFilter,
                },
                {
                    name: `${'edcname'}`,
                    value: props.selectedEDCFilter,
                },
                { // contract type
                    name: props.filtersGraphQLAccountData.filters[2].name,
                    value: props.filtersGraphQLAccountData.filters[2].value,
                },
                { // contract status
                    name: props.filtersGraphQLAccountData.filters[3].name,
                    value: props.filtersGraphQLAccountData.filters[3].value,

                },
                { // brand name
                    name: props.filtersGraphQLAccountData.filters[4].name,
                    value: props.filtersGraphQLAccountData.filters[4].value,
                },
                { // broker type
                    name: props.filtersGraphQLAccountData.filters[5].name,
                    value: props.filtersGraphQLAccountData.filters[5].value,
                },
                {// contract signdate - start
                    name: props.filtersGraphQLAccountData.filters[6].name,
                    value: props.filtersGraphQLAccountData.filters[6].value,
                    comparator: props.filtersGraphQLAccountData.filters[6].comparator
                },
                { //contract startdate - start
                    name: props.filtersGraphQLAccountData.filters[7].name,
                    value: props.filtersGraphQLAccountData.filters[7].value,
                    comparator: props.filtersGraphQLAccountData.filters[7].comparator
                },
                {//contract startdate - end
                    name: props.filtersGraphQLAccountData.filters[8].name,
                    value: props.filtersGraphQLAccountData.filters[8].value,
                    comparator: props.filtersGraphQLAccountData.filters[8].comparator
                },
                {//contract endate - start
                    name: props.filtersGraphQLAccountData.filters[9].name,
                    value: props.filtersGraphQLAccountData.filters[9].value,
                    comparator: props.filtersGraphQLAccountData.filters[9].comparator
                },
                {//contract endate - end
                    name: props.filtersGraphQLAccountData.filters[10].name,
                    value: props.filtersGraphQLAccountData.filters[10].value,
                    comparator: props.filtersGraphQLAccountData.filters[10].comparator
                },
                //contract endate - end
                props.filtersGraphQLAccountData.filters[11].value !== undefined ? {
                    name: props.filtersGraphQLAccountData.filters[11].name,
                    value: props.filtersGraphQLAccountData.filters[11].value,
                    comparator: props.filtersGraphQLAccountData.filters[11].comparator
                } : null,
            ]
        })
        //console.log(props.filtersGraphQLAccountData)
        // console.log(accountLevelFilters[0])
        if (accountLevelFilters[0].filters[11].value !== undefined) {
            //console.log(accountLevelFilters[0])
            // fetch the data on the basis of filters
            response_AccountFilterData = API.graphql(graphqlOperation(getISOEDCSelectedAccountLevel,
                {
                    filters: accountLevelFilters[0].filters,
                    sort_by: `${props.marginType}`
                }))
                .then(({ data: getAccountDataQuery }) => {
                    // console.log(getAccountDataQuery.getAccountData)
                    return getAccountDataQuery.getAccountData
                }).catch(a => {
                    console.log(a.errors[0].message)
                });;
            return {
                promise_graphlqlAccountFilterData: response_AccountFilterData
            };
        }
    }

    CustomerAccountLevelDetailsUI = () => {
        var AccountDataChart_KeyValuePair_AccountData_Gross = [], AccountDataChart_KeyValuePair_AccountData_Unit = [], dataLength = 0
        const [promiseAccountData, setPromiseAccountData] = React.useState()

        // unpromis fetch data of graphql and save it in local state
        if (this.state.promise_graphlqlAccountFilterData !== null) {
            if (this.state.promise_graphlqlAccountFilterData !== undefined) {
                this.state.promise_graphlqlAccountFilterData.then(a => {
                    if (a !== undefined) {
                        //console.log(a)
                        setPromiseAccountData(a)
                    }
                    AccountDataChart_KeyValuePair_AccountData_Gross = []
                    AccountDataChart_KeyValuePair_AccountData_Unit = []
                    dataLength = 0
                })
            }
        }
        // console.log(promiseAccountData)
        // reorganzie data on the basis of visualization graph requirement and then sort it
        if (this.state.promise_graphlqlAccountFilterData !== null) {
            if (this.state.promise_graphlqlAccountFilterData !== undefined) {
                if (promiseAccountData !== undefined) {
                    promiseAccountData.map(a => {
                        a.edcs.map(b => {
                            b.accounts.map(c => {
                                AccountDataChart_KeyValuePair_AccountData_Gross.push({
                                    ISO: a.iso,
                                    EDCName: b.edcname,
                                    color: isoColor(a.iso),
                                    AccountNo: c.account,
                                    GrossMargin: c.grossmargin,
                                    UnitMargin: c.grossmargin / c.contractvolume,
                                    Volume: c.contractvolume,
                                    ContractType: c.contracttype,
                                    Brand: c.brand,
                                    Broker: c.broker,
                                    ContractStatus: c.contractstatus,
                                    ContractSignDate: c.contractsigneddate,
                                    ContractStartDate: c.contractstartdate,
                                    ContractEndDate: c.contractenddate,
                                })
                                AccountDataChart_KeyValuePair_AccountData_Unit.push({
                                    ISO: a.iso,
                                    EDCName: b.edcname,
                                    color: isoColor(a.iso),
                                    AccountNo: c.account,
                                    GrossMargin: c.grossmargin,
                                    UnitMargin: c.grossmargin / c.contractvolume,
                                    Volume: c.contractvolume,
                                    ContractType: c.contracttype,
                                    Brand: c.brand,
                                    Broker: c.broker,
                                    ContractStatus: c.contractstatus,
                                    ContractSignDate: c.contractsigneddate,
                                    ContractStartDate: c.contractstartdate,
                                    ContractEndDate: c.contractenddate,
                                    // ...(c.contractstatus ? { ContractStatus: c.contractstatus } : null)
                                })
                                dataLength = AccountDataChart_KeyValuePair_AccountData_Gross.length
                                return null
                            })
                            return null
                        })
                        return null
                    })
                    AccountDataChart_KeyValuePair_AccountData_Gross.sort((a, b) => b.GrossMargin - a.GrossMargin)
                    AccountDataChart_KeyValuePair_AccountData_Unit.sort((a, b) => b.UnitMargin - a.UnitMargin)
                }
            }
        }
        //  console.log(this.props.filtersGraphQLAccountData)
        //  console.log(this.state.accountLevelFilters[0])
       // console.log(AccountDataChart_KeyValuePair_AccountData_Gross)
        //console.log(AccountDataChart_KeyValuePair_AccountData_Unit)
        // console.log(dataLength)
        const CustomToolTipAccountDetails = (props) => {
            const { active, payload } = props;
            var data = 0;
            // console.log(payload)
            if (!active || !payload) {
                return null;
            }
            else {
                data = payload[0].payload;
                // console.log(dollarOrMWh)
                // console.log(data)
                return (
                    <>
                        <Card>
                            <CardHeader>
                                <strong style={{ color: data.color }}>
                                    Account No: {data.AccountNo}
                                    <br />Gross Margin: {aggregateValue(data.GrossMargin, true)}
                                    <br />Unit Margin: {(data.UnitMargin * 1000).toFixed(2)} $/MWh
                                    <br /> Volume: {aggregateValue(data.Volume, false)}
                                    <br /> Contract Type: {data.ContractType}
                                    {data.ContractStatus !== undefined ? <><br />Contract Status: {data.ContractStatus}</> : null}
                                    {data.Brand !== undefined ? <><br />Brand: {data.Brand}</> : null}
                                    {data.Broker !== undefined ? <><br />Broker: {data.Broker}</> : null}
                                    <br /> Contract Sign Date: {data.ContractSignDate}
                                    <br /> Contract Start Date: {data.ContractStartDate}
                                    <br /> Contract End Date: {data.ContractEndDate}
                                </strong>
                            </CardHeader>
                        </Card>
                    </>
                );
            }
        };

        return (
            <>
                {AccountDataChart_KeyValuePair_AccountData_Gross[0] !== undefined ?
                    <>{this.props.marginType !== "unit" ?
                        <>
                            < ResponsiveContainer width="100%" height="100%" aspect={
                                dataLength < 10 ? 2 / 0.5 :
                                    dataLength <= 10 && dataLength >= 30 ? 2 / 1 :
                                        dataLength > 30 && dataLength <= 60 ? 2 / 1.5 : 2 / 2}>
                                <BarChart layout="vertical" height={1100} data={AccountDataChart_KeyValuePair_AccountData_Gross} margin={{ top: 130, right: 30, bottom: -80, left: 40 }}>
                                    <Brush
                                        //dataKey='GrossMargin'
                                        height={100}
                                        stroke="#000000"
                                        y={0}
                                        startIndex={0}
                                        endIndex={dataLength > 100 ? 100 : 0}>
                                        <BarChart>
                                            <Bar type="monotone" dataKey="GrossMargin" barSize={20}>
                                                {
                                                    AccountDataChart_KeyValuePair_AccountData_Gross.map((entry, index) => {
                                                        return <Cell key={`cell-${index}`} fill={entry.color} />
                                                    })
                                                }
                                            </Bar>
                                        </BarChart>
                                    </Brush>
                                    <CartesianGrid stroke='#f5f5f5' />
                                    <XAxis tick={<CustomXAxisDollarVertical />} type="number" />
                                    <YAxis dataKey="AccountNo" tick={<CustomizedAxisTickVertical />} type="category" />
                                    <Tooltip content={<CustomToolTipAccountDetails />} />
                                    <Bar type="monotone" dataKey="GrossMargin" barSize={20} >
                                        {
                                            AccountDataChart_KeyValuePair_AccountData_Gross.map((entry, index) => {
                                                return <Cell key={`cell-${index}`} fill={entry.color} />
                                            })
                                        }
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer><br></br>
                        </>
                        :
                        <>
                            <ResponsiveContainer width="100%" height={1100} aspect={
                                dataLength < 10 ? 2 / 0.5 :
                                    dataLength <= 10 && dataLength >= 30 ? 2 / 1 :
                                        dataLength > 30 && dataLength <= 60 ? 2 / 1.5 : 2 / 2}>
                                <BarChart layout="vertical" data={AccountDataChart_KeyValuePair_AccountData_Unit} margin={{ top: 130, right: 30, bottom: -80, left: 40 }}>
                                    <Brush
                                        //dataKey='GrossMargin'//
                                        height={100}
                                        stroke="#000000"
                                        y={0}
                                        startIndex={0}
                                        endIndex={dataLength > 100 ? 100 : 0}>
                                        <BarChart>
                                            <Bar type="monotone" dataKey="UnitMargin" barSize={20}>
                                                {
                                                    AccountDataChart_KeyValuePair_AccountData_Unit.map((entry, index) => {
                                                        return <Cell key={`cell-${index}`} fill={entry.color} />
                                                    })
                                                }
                                            </Bar>
                                        </BarChart>
                                    </Brush>
                                    <CartesianGrid stroke='#f5f5f5' />
                                    <XAxis tick={<CustomXAxisDollarMWhVertical />} type="number" />
                                    <YAxis dataKey="AccountNo" tick={<CustomizedAxisTickVertical />} type="category" />
                                    <Tooltip content={<CustomToolTipAccountDetails />} />
                                    <Bar type="monotone" dataKey="UnitMargin" barSize={20} >
                                        {
                                            AccountDataChart_KeyValuePair_AccountData_Unit.map((entry, index) => {
                                                return <Cell key={`cell-${index}`} fill={entry.color} />
                                            })
                                        }
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer><br></br><br></br>
                        </>
                    }
                    </>
                    :
                    <>   <br></br><br></br><LinearProgress /></>}
            </>
        )
    }
    render() {
        return (<this.CustomerAccountLevelDetailsUI />)
    }
}
export default CustomerAccountLevelDetails;